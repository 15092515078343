<template>
  <b-modal size="md" :no-close-on-esc="!canCloseModalAdd" :no-close-on-backdrop="!canCloseModalAdd" v-model="computedShowModal" :title="$t('payments.modal_add.title')" hide-footer @hidden="closeModalAdd">
    <p>{{ $t('payments.modal_add.sub_title') }}</p><br/>
    <b-form ref="formAddAccount" @submit.prevent="submitCreateAccount">
        <b-form-group :label="$t('payments.form.bank')">
          <el-select class="w-100" v-model="v$.form.bank_id.$model" size="small" filterable :placeholder="$t('payments.placeholder.bank')">
            <el-option v-for="item in bankList" :key="item.id" :value="item.id" :label="item.bank_name"/>
          </el-select>
          <b-form-invalid-feedback :state="!v$.form.bank_id.$invalid" v-if="v$.form.bank_id.$errors[0]">
            {{ $t('validation.' + v$.form.bank_id.$errors[0].$validator) }}
          </b-form-invalid-feedback>
        </b-form-group>
        <b-form-group :label="$t('payments.form.account_number')">
          <el-input
            v-model="v$.form.account_number.$model"
            size="small"
            :placeholder="$t('payments.placeholder.account_number')"
          />
          <b-form-invalid-feedback :state="!v$.form.account_number.$invalid" v-if="v$.form.account_number.$errors[0]">
            {{ $t('validation.' + v$.form.account_number.$errors[0].$validator) }}
          </b-form-invalid-feedback>
        </b-form-group>
        <b-form-group :label="$t('payments.form.account_name')">
          <el-input
            v-model="v$.form.account_name.$model"
            size="small"
            :placeholder="$t('payments.placeholder.account_name')"
          />
          <b-form-invalid-feedback :state="!v$.form.account_name.$invalid" v-if="v$.form.account_name.$errors[0]">
            {{ $t('validation.' + v$.form.account_name.$errors[0].$validator) }}
          </b-form-invalid-feedback>
        </b-form-group>
        <b-form-group :label="$t('payments.form.bank_branch_name')">
          <el-input
            v-model="v$.form.bank_branch_name.$model"
            size="small"
            :placeholder="$t('payments.placeholder.bank_branch_name')"
          />
          <b-form-invalid-feedback :state="!v$.form.bank_branch_name.$invalid" v-if="v$.form.bank_branch_name.$errors[0]">
            {{ $t('validation.' + v$.form.bank_branch_name.$errors[0].$validator) }}
          </b-form-invalid-feedback>
        </b-form-group>
        <b-form-group :label="$t('payments.form.bank_branch_city')">
          <el-input
            v-model="v$.form.bank_branch_city.$model"
            size="small"
            :placeholder="$t('payments.placeholder.bank_branch_city')"
          />
          <b-form-invalid-feedback :state="!v$.form.bank_branch_city.$invalid" v-if="v$.form.bank_branch_city.$errors[0]">
            {{ $t('validation.' + v$.form.bank_branch_city.$errors[0].$validator) }}
          </b-form-invalid-feedback>
        </b-form-group>
        <span v-if="companyProfile.business_entity_id !== id_perorangan">
          <b-form-group :label="$t('payments.form.bank_branch_city')">
            <el-input
              v-model="v$.form.bank_branch_city.$model"
              size="small"
              :placeholder="$t('payments.placeholder.bank_branch_city')"
            />
            <b-form-invalid-feedback :state="!v$.form.bank_branch_city.$invalid" v-if="v$.form.bank_branch_city.$errors[0]">
              {{ $t('validation.' + v$.form.bank_branch_city.$errors[0].$validator) }}
            </b-form-invalid-feedback>
          </b-form-group>
          <b-form-group :label="$t('payments.form.pic_company_name')">
            <el-input
              v-model="v$.form.pic_company_name.$model"
              size="small"
              :placeholder="$t('payments.placeholder.pic_company_name')"
            />
            <b-form-invalid-feedback :state="!v$.form.pic_company_name.$invalid" v-if="v$.form.pic_company_name.$errors[0]">
              {{ $t('validation.' + v$.form.pic_company_name.$errors[0].$validator) }}
            </b-form-invalid-feedback>
          </b-form-group>
          <b-form-group :label="$t('payments.form.pic_company_email')">
            <el-input
              v-model="v$.form.pic_company_email.$model"
              size="small"
              :placeholder="$t('payments.placeholder.pic_company_email')"
            />
            <b-form-invalid-feedback :state="!v$.form.pic_company_email.$invalid" v-if="v$.form.pic_company_email.$errors[0]">
              {{ $t('validation.' + v$.form.pic_company_email.$errors[0].$validator) }}
            </b-form-invalid-feedback>
          </b-form-group>
          <b-form-group :label="$t('payments.form.pic_company_phone_number')">
            <el-input
              v-model="v$.form.pic_company_phone_number.$model"
              size="small"
              :placeholder="$t('payments.placeholder.pic_company_phone_number')"
            />
            <b-form-invalid-feedback :state="!v$.form.pic_company_phone_number.$invalid" v-if="v$.form.pic_company_phone_number.$errors[0]">
              {{ $t('validation.' + v$.form.pic_company_phone_number.$errors[0].$validator) }}
            </b-form-invalid-feedback>
          </b-form-group>
          <b-form-group :label="$t('payments.form.pic_finance_company_name')">
            <el-input
              v-model="v$.form.pic_finance_company_name.$model"
              size="small"
              :placeholder="$t('payments.placeholder.pic_finance_company_name')"
            />
            <b-form-invalid-feedback :state="!v$.form.pic_finance_company_name.$invalid" v-if="v$.form.pic_finance_company_name.$errors[0]">
              {{ $t('validation.' + v$.form.pic_finance_company_name.$errors[0].$validator) }}
            </b-form-invalid-feedback>
          </b-form-group>
          <b-form-group :label="$t('payments.form.pic_finance_company_email')">
            <el-input
              v-model="v$.form.pic_finance_company_email.$model"
              size="small"
              :placeholder="$t('payments.placeholder.pic_finance_company_email')"
            />
            <b-form-invalid-feedback :state="!v$.form.pic_finance_company_email.$invalid" v-if="v$.form.pic_finance_company_email.$errors[0]">
              {{ $t('validation.' + v$.form.pic_finance_company_email.$errors[0].$validator) }}
            </b-form-invalid-feedback>
          </b-form-group>
          <b-form-group :label="$t('payments.form.pic_finance_company_phone_number')">
            <el-input
              v-model="v$.form.pic_finance_company_phone_number.$model"
              size="small"
              :placeholder="$t('payments.placeholder.pic_finance_company_phone_number')"
            />
            <b-form-invalid-feedback :state="!v$.form.pic_finance_company_phone_number.$invalid" v-if="v$.form.pic_finance_company_phone_number.$errors[0]">
              {{ $t('validation.' + v$.form.pic_finance_company_phone_number.$errors[0].$validator) }}
            </b-form-invalid-feedback>
          </b-form-group>
        </span>
        <b-form-group :label="$t('payments.form.id_card_no')">
          <el-input
            v-model="v$.form.id_card_no.$model"
            size="small"
            :placeholder="$t('payments.placeholder.id_card_no')"
          />
          <b-form-invalid-feedback :state="!v$.form.id_card_no.$invalid" v-if="v$.form.id_card_no.$errors[0]">
            {{ $t('validation.' + v$.form.id_card_no.$errors[0].$validator) }}
          </b-form-invalid-feedback>
        </b-form-group>
        <div v-for="(item, i) in file_form_list" :key="i" >
          <div class="mb-3" v-if="i % 2 == 0">
            <div class="row">
              <div class="col-md-6" v-for="index in 2" :key="index">
                <span v-if="file_form_list[i + (index-1)]">
                  <label class="block text-sm font-weight-semibold">
                    {{ file_form_list[i + (index-1)].name }}<span class="text-danger">*</span>
                  </label>
                  <b-form-file
                    v-model="file_model[file_form_list[i + (index-1)].var]"
                    :accept="file_form_list[i + (index-1)].whitelist.join(',')"
                    placeholder="Choose a file or drop it here."
                    :disabled="uploading[file_form_list[i + (index-1)].var]"
                    @input="file_uploader[file_form_list[i + (index-1)].var]($event)"
                    drop-placeholder="Drop file here."
                  ></b-form-file>
                  <span style="font-size: 10px">Maks. 10MB</span>
                  <div class="d-block invalid-feedback" v-if="v$.form[file_form_list[i + (index-1)].var].$errors[0]">
                    {{ $t('validation.' + v$.form[file_form_list[i + (index-1)].var].$errors[0].$validator) }}
                  </div>
                  <el-progress v-if="uploading[file_form_list[i + (index-1)].var]" :percentage="upload_progress[file_form_list[i + (index-1)].var]" :status="upload_status[file_form_list[i + (index-1)].var]"></el-progress>
                </span>
              </div>
            </div>
          </div>
        </div>
        <div class="d-flex flex-row-reverse">
          <el-button size="small" @click="submitCreateAccount" :loading="loading.create_account" class="btn btn-sm ml-2 btn-primary">{{ $t('general.submit') }}</el-button>
          <el-button size="small" @click="closeModalAdd" :disabled="!canCloseModalAdd" type="secondary">{{ $t('general.cancel') }}</el-button>
        </div>
    </b-form>
  </b-modal>
</template>
<script>
import { isEmpty, uniqBy } from 'lodash';
import useVuelidate from '@vuelidate/core';
import {
  required, numeric, email, requiredIf,
} from '@vuelidate/validators';

const id_perorangan = '06ef4ce0-4af2-4e3f-9c11-d275a8d76a9c';

export default {
  name: 'CreateSettlementAccount',
  setup() {
    return { v$: useVuelidate() };
  },
  props: {
    bankList: {
      type: Array,
      default() {
        return [];
      },
    },
    showModalAdd: {
      type: Boolean,
      default: false,
    },
  },
  data() {
    return {
      id_perorangan,
      uploading: {
        npwp_image: false,
        id_card_image: false,
        akta_pendirian_doc: false,
        siup_doc: false,
        tdp_doc: false,
        sk_kementrian_doc: false,
        skdp_doc: false,
      },
      upload_progress: {
        npwp_image: 0,
        id_card_image: 0,
        akta_pendirian_doc: 0,
        siup_doc: 0,
        tdp_doc: 0,
        sk_kementrian_doc: 0,
        skdp_doc: 0,
      },
      upload_status: {
        npwp_image: null,
        id_card_image: null,
        akta_pendirian_doc: null,
        siup_doc: null,
        tdp_doc: null,
        sk_kementrian_doc: null,
        skdp_doc: null,
      },
      file_model: {
        npwp_image: null,
        id_card_image: null,
        akta_pendirian_doc: null,
        siup_doc: null,
        tdp_doc: null,
        sk_kementrian_doc: null,
        skdp_doc: null,
      },
      form: {
        bank_id: '',
        account_number: '',
        account_name: '',
        bank_branch_name: '',
        bank_branch_city: '',
        npwp_image: null,
        id_card_image: null,
        id_card_no: '',
        akta_pendirian_doc: '',
        siup_doc: '',
        tdp_doc: '',
        sk_kementrian_doc: '',
        skdp_doc: '',
        pic_company_name: '',
        pic_company_phone_number: '',
        pic_company_position: '',
        pic_company_email: '',
        pic_finance_company_name: '',
        pic_finance_company_phone_number: '',
        pic_finance_company_position: '',
        pic_finance_company_email: '',
        is_disbursement_account: true,
      },
      file_form_list: [
        {
          name: 'KTP',
          type: 'image',
          whitelist: ['.jpeg', '.jpg', '.png'],
          var: 'id_card_image',
          preview: null,
          loading: false,
        },
        {
          name: 'NPWP',
          type: 'image',
          whitelist: ['.jpeg', '.jpg', '.png'],
          var: 'npwp_image',
          preview: null,
          loading: false,
        },
      ],
      file_list: {
        npwp_image: [],
        id_card_image: [],
        akta_pendirian_doc: [],
        siup_doc: [],
        tdp_doc: [],
        sk_kementrian_doc: [],
        skdp_doc: [],
      },
      file_uploader: {
        npwp_image: (param) => this.handleSendFile(param, 'npwp_image'),
        id_card_image: (param) => this.handleSendFile(param, 'id_card_image'),
        akta_pendirian_doc: (param) => this.handleSendFile(param, 'akta_pendirian_doc'),
        siup_doc: (param) => this.handleSendFile(param, 'siup_doc'),
        tdp_doc: (param) => this.handleSendFile(param, 'tdp_doc'),
        sk_kementrian_doc: (param) => this.handleSendFile(param, 'sk_kementrian_doc'),
        skdp_doc: (param) => this.handleSendFile(param, 'skdp_doc'),
      },
      loading: {
        create_account: false,
      },
      show_modal: this.showModalAdd,
    };
  },
  computed: {
    activeWorkspace() {
      return this.$store.state.workspace.activeWorkspace;
    },
    canCloseModalAdd() {
      return !this.loading.create_account;
    },
    companyProfile() {
      return this.$store.state.auth.companyProfile || {};
    },
    computedShowModal: {
      get() {
        return this.show_modal;
      },
      set(val) {
        this.show_modal = val;
      },
    },
  },
  validations() {
    return {
      form: {
        bank_id: {
          required,
        },
        bank_branch_name: {
          required,
        },
        bank_branch_city: {
          required,
        },
        account_number: {
          required,
          numeric,
        },
        account_name: {
          required,
        },
        pic_company_name: {
          required: requiredIf(this.companyProfile.business_entity_id !== id_perorangan && this.form.is_disbursement_account),
        },
        pic_company_phone_number: {
          required: requiredIf(this.companyProfile.business_entity_id !== id_perorangan && this.form.is_disbursement_account),
        },
        pic_company_email: {
          required: requiredIf(this.companyProfile.business_entity_id !== id_perorangan && this.form.is_disbursement_account),
          email,
        },
        pic_company_position: {
          required: requiredIf(this.companyProfile.business_entity_id !== id_perorangan && this.form.is_disbursement_account),
        },
        pic_finance_company_name: {
          required: requiredIf(this.companyProfile.business_entity_id !== id_perorangan && this.form.is_disbursement_account),
        },
        pic_finance_company_phone_number: {
          required: requiredIf(this.companyProfile.business_entity_id !== id_perorangan && this.form.is_disbursement_account),
        },
        pic_finance_company_email: {
          required: requiredIf(this.companyProfile.business_entity_id !== id_perorangan && this.form.is_disbursement_account),
          email,
        },
        pic_finance_company_position: {
          required: requiredIf(this.companyProfile.business_entity_id !== id_perorangan && this.form.is_disbursement_account),
        },
        id_card_image: {
          required: requiredIf(this.form.is_disbursement_account),
        },
        id_card_no: {
          required: requiredIf(this.form.is_disbursement_account),
        },
        npwp_image: {
          required: requiredIf(this.form.is_disbursement_account),
        },
        akta_pendirian_doc: {
          required: requiredIf(this.companyProfile.business_entity_id !== id_perorangan && this.form.is_disbursement_account),
        },
        siup_doc: {
          required: requiredIf(this.companyProfile.business_entity_id !== id_perorangan && this.form.is_disbursement_account),
        },
        tdp_doc: {
          required: requiredIf(this.companyProfile.business_entity_id !== id_perorangan && this.form.is_disbursement_account),
        },
        sk_kementrian_doc: {
          required: requiredIf(this.companyProfile.business_entity_id !== id_perorangan && this.form.is_disbursement_account),
        },
        skdp_doc: {
          required: requiredIf(this.companyProfile.business_entity_id !== id_perorangan),
        },
      },
    };
  },
  methods: {
    submitCreateAccount() {
      this.v$.form.$touch();
      if (this.v$.form.$error) return;
      this.$emit('submitCreateAccount', this.form);
    },
    closeModalAdd() {
      this.$emit('closeModalAdd');
    },
    handleSendFile(file, target) {
      this.$emit('handleSendFile', file, target);
    },
  },
  watch: {
    companyProfile() {
      if (!isEmpty(this.companyProfile) && this.companyProfile.business_entity_id !== id_perorangan) {
        this.file_form_list.push({
          name: 'Akta Pendirian',
          type: 'document',
          whitelist: ['.pdf'],
          var: 'akta_pendirian_doc',
          preview: null,
          loading: false,
        },
        {
          name: 'SIUP',
          type: 'document',
          whitelist: ['.pdf'],
          var: 'siup_doc',
          preview: null,
          loading: false,
        },
        {
          name: 'TDP',
          type: 'document',
          whitelist: ['.pdf'],
          var: 'tdp_doc',
          preview: null,
          loading: false,
        },
        {
          name: 'SK Kementrian',
          type: 'document',
          whitelist: ['.pdf'],
          var: 'sk_kementrian_doc',
          preview: null,
          loading: false,
        },
        {
          name: 'SKDP',
          type: 'document',
          whitelist: ['.pdf'],
          var: 'skdp_doc',
          preview: null,
          loading: false,
        });
        this.file_form_list = uniqBy(this.file_form_list, 'var');
      } else {
        this.file_form_list = [{
          name: 'KTP',
          type: 'image',
          whitelist: ['.jpeg', '.jpg', '.png'],
          var: 'id_card_image',
          preview: null,
          loading: false,
        },
        {
          name: 'NPWP',
          type: 'image',
          whitelist: ['.jpeg', '.jpg', '.png'],
          var: 'npwp_image',
          preview: null,
          loading: false,
        }];
      }
    },
    showModalAdd() {
      if (this.showModalAdd) this.v$.form.$touch();
      this.show_modal = this.showModalAdd;
    },
  },
};
</script>
