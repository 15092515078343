<template>
  <b-modal size="lg" v-model="computedShowModal" :title="$t('transaction.title_detail')" hide-footer @hidden="closeModal">
    <table class="table table-bordered">
      <tr class="p-2">
        <td width="25%">Invoice No.</td>
        <td>{{ trx.invoice_number }}</td>
      </tr>
      <tr>
        <td width="25%">Ticket No.</td>
        <td>#{{ trx.conversation_code }}</td>
      </tr>
      <tr>
        <td width="25%">Total Amount</td>
        <td>Rp. {{ trx.total_amount }}</td>
      </tr>
      <tr>
        <td width="25%">Status</td>
        <td><el-tag size="small" effect="dark" :type="trx.status_color">{{ trx.status_str }}</el-tag></td>
      </tr>
    </table>
    <table class="table">
      <thead>
        <tr>
          <th>Product</th>
          <th>Price</th>
          <th>Quantity</th>
          <th>Total Price</th>
        </tr>
      </thead>
      <tbody>
        <tr v-for="item in trxDetails" :key="item.id">
          <td>{{ item.raw_product.name }}</td>
          <td>Rp. {{ item.raw_product.price ? item.raw_product.price.toLocaleString() : 0 }}</td>
          <td>{{ item.qty }}</td>
          <td>Rp. {{ item.raw_product.total_price ? item.raw_product.total_price.toLocaleString() : 0 }}</td>
        </tr>
      </tbody>
      <tfoot>
        <tr>
          <td class="font-weight-bold" colspan="3">Subtotal</td>
          <td class="font-weight-bold">Rp. {{ sumBy(trxDetails, 'total_price').toLocaleString() }}</td>
        </tr>
      </tfoot>
    </table>
  </b-modal>
</template>
<script>
import { sumBy } from 'lodash';

export default {
  name: 'TransactionDetail',
  props: {
    showModal: {
      type: Boolean,
      default: false,
    },
    trx: {
      type: Object,
      default() {
        return {};
      },
    },
    trxDetails: {
      type: Array,
      default() {
        return [];
      },
    },
  },
  data() {
    return {
      sumBy,
      show_modal: false,
    };
  },
  computed: {
    activeWorkspace() {
      return this.$store.state.workspace.activeWorkspace;
    },
    cancloseModal() {
      return !this.loading.create_account;
    },
    companyProfile() {
      return this.$store.state.auth.companyProfile || {};
    },
    computedShowModal: {
      get() {
        return this.show_modal;
      },
      set(val) {
        this.show_modal = val;
      },
    },
  },
  methods: {
    closeModal() {
      this.$emit('closeModal');
    },
  },
  watch: {
    showModal() {
      this.show_modal = this.showModal;
    },
    trx() {
      if (this.trx) {
        const status_str = {
          success: 'Success',
          waiting_payment: 'Waiting Payment',
          paid: 'Paid',
          canceled: 'Canceled',
          refund: 'Refund',
        };
        const status_color = {
          success: 'success',
          waiting_payment: 'warning',
          paid: 'success',
          canceled: 'danger',
          refund: 'info',
        };
        this.trx.conversation_code = this.trx.conversation.code;
        this.trx.total_amount = this.trx.total_amount.toLocaleString();
        this.trx.status_str = status_str[this.trx.status];
        this.trx.status_color = status_color[this.trx.status];
      }
    },
  },
};
</script>
